
  import { mapState } from "vuex";

  export default {
    name: "SearchBar",
    components: {
      Search: () => import("@/components/search/Search"),
    },
    watch: {
      searchBar(newValue) {
        if (newValue) {
          this.$nextTick(() => {
            const searchComponent = this.$refs.search;
            if (searchComponent) {
              searchComponent.focusSearchInput();
            }
          });
        }
      }
    },
    computed : {
      ...mapState('header', {
        scroll: state => state.scroll,
        searchBar: state => state.displaySearchBar,
      })
    },
  }
